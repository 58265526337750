define('ember-in-viewport/mixins/in-viewport', ['exports', 'ember-in-viewport/utils/can-use-dom', 'ember-in-viewport/utils/can-use-raf', 'ember-in-viewport/utils/find-elem', 'ember-in-viewport/utils/can-use-intersection-observer', 'ember-in-viewport/utils/is-in-viewport', 'ember-in-viewport/utils/check-scroll-direction'], function (exports, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _isInViewport, _checkScrollDirection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var rAFIDS = {};
  var lastDirection = {};
  var lastPosition = {};

  exports.default = Ember.Mixin.create({
    /**
     * IntersectionObserverEntry
     *
     * @property intersectionObserver
     * @default null
     */
    intersectionObserver: null,
    viewportExited: Ember.computed.not('viewportEntered').readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var options = Ember.assign({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      Ember.setProperties(this, options);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (!_canUseDom.default) {
        return;
      }

      var viewportEnabled = Ember.get(this, 'viewportEnabled');
      if (viewportEnabled) {
        this._startListening();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this._unbindListeners();
    },
    _buildOptions: function _buildOptions() {
      var defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var owner = Ember.getOwner(this);

      if (owner) {
        return Ember.assign(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    _startListening: function _startListening() {
      var _this = this;

      this._setInitialViewport();
      this._addObserverIfNotSpying();
      this._bindScrollDirectionListener(Ember.get(this, 'viewportScrollSensitivity'));

      if (!Ember.get(this, 'viewportUseRAF')) {
        Ember.get(this, 'viewportListeners').forEach(function (listener) {
          var context = listener.context,
              event = listener.event;

          context = Ember.get(_this, 'scrollableArea') || context;
          _this._bindListeners(context, event);
        });
      }
    },
    _addObserverIfNotSpying: function _addObserverIfNotSpying() {
      if (!Ember.get(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, this._unbindIfEntered);
      }
    },
    _setViewportEntered: function _setViewportEntered() {
      var scrollableArea = Ember.get(this, 'scrollableArea') ? document.querySelector(Ember.get(this, 'scrollableArea')) : null;

      var element = Ember.get(this, 'element');

      if (!element) {
        return;
      }

      if (Ember.get(this, 'viewportUseIntersectionObserver')) {
        // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        // IntersectionObserver takes either a Document Element or null for `root`
        var _viewportTolerance = this.viewportTolerance,
            _viewportTolerance$to = _viewportTolerance.top,
            top = _viewportTolerance$to === undefined ? 0 : _viewportTolerance$to,
            _viewportTolerance$le = _viewportTolerance.left,
            left = _viewportTolerance$le === undefined ? 0 : _viewportTolerance$le,
            _viewportTolerance$bo = _viewportTolerance.bottom,
            bottom = _viewportTolerance$bo === undefined ? 0 : _viewportTolerance$bo,
            _viewportTolerance$ri = _viewportTolerance.right,
            right = _viewportTolerance$ri === undefined ? 0 : _viewportTolerance$ri;

        var options = {
          root: scrollableArea,
          rootMargin: top + 'px ' + right + 'px ' + bottom + 'px ' + left + 'px',
          threshold: Ember.get(this, 'intersectionThreshold')
        };

        this.intersectionObserver = new IntersectionObserver(Ember.run.bind(this, this._onIntersection), options);
        this.intersectionObserver.observe(element);
      } else {
        var height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
        var width = scrollableArea ? scrollableArea.offsetWidth : window.innerWidth;
        var boundingClientRect = element.getBoundingClientRect();

        if (boundingClientRect) {
          this._triggerDidAccessViewport((0, _isInViewport.default)(boundingClientRect, height, width, Ember.get(this, 'viewportTolerance')));
          if (Ember.get(this, 'viewportUseRAF')) {
            rAFIDS[Ember.get(this, 'elementId')] = window.requestAnimationFrame(Ember.run.bind(this, this._setViewportEntered));
          }
        }
      }
    },


    /**
     * callback provided to IntersectionObserver
     *
     * @method _onIntersection
     * @param {Array} - entries
     */
    _onIntersection: function _onIntersection(entries) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var entry = entries[0];

      if (entry.isIntersecting) {
        Ember.set(this, 'viewportEntered', true);
        this.trigger('didEnterViewport');
      } else if (entry.intersectionRatio <= 0) {
        // exiting viewport
        Ember.set(this, 'viewportEntered', false);
        this.trigger('didExitViewport');
      }
    },
    _triggerDidScrollDirection: function _triggerDidScrollDirection() {
      var contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (false && !(contextEl) && Ember.assert('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var elementId = Ember.get(this, 'elementId');
      var lastDirectionForEl = lastDirection[elementId];
      var lastPositionForEl = lastPosition[elementId];
      var newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };

      var scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      var directionChanged = scrollDirection !== lastDirectionForEl;

      if (scrollDirection && directionChanged && Ember.get(this, 'viewportEntered')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }

      lastPosition[elementId] = newPosition;
    },
    _triggerDidAccessViewport: function _triggerDidAccessViewport() {
      var hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      var viewportEntered = Ember.get(this, 'viewportEntered');
      var didEnter = !viewportEntered && hasEnteredViewport;
      var didLeave = viewportEntered && !hasEnteredViewport;
      var triggeredEventName = '';

      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }

      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }

      if (Ember.get(this, 'viewportSpy') || !viewportEntered) {
        Ember.set(this, 'viewportEntered', hasEnteredViewport);
      }

      this.trigger(triggeredEventName);
    },
    _unbindIfEntered: function _unbindIfEntered() {
      if (!Ember.get(this, 'viewportSpy') && Ember.get(this, 'viewportEntered')) {
        this._unbindListeners();
        this.removeObserver('viewportEntered', this, this._unbindIfEntered);
        Ember.set(this, 'viewportEntered', true);
      }
    },
    _setInitialViewport: function _setInitialViewport() {
      var _this2 = this;

      return Ember.run.scheduleOnce('afterRender', this, function () {
        _this2._setViewportEntered();
      });
    },
    _debouncedEventHandler: function _debouncedEventHandler(methodName) {
      var _this3 = this;

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      (false && !(methodName) && Ember.assert('You must pass a methodName to _debouncedEventHandler', methodName));
      (false && !(Ember.typeOf(methodName) === 'string') && Ember.assert('methodName must be a string', Ember.typeOf(methodName) === 'string'));


      Ember.run.debounce(this, function () {
        return _this3[methodName].apply(_this3, _toConsumableArray(args));
      }, Ember.get(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener: function _bindScrollDirectionListener() {
      var _this4 = this;

      var sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (false && !(sensitivity) && Ember.assert('sensitivity cannot be 0', sensitivity));


      var contextEl = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(contextEl);

      elem.addEventListener('scroll', function () {
        _this4._debouncedEventHandler('_triggerDidScrollDirection', elem, sensitivity);
      });
    },
    _unbindScrollDirectionListener: function _unbindScrollDirectionListener() {
      var _this5 = this;

      var elementId = Ember.get(this, 'elementId');
      var context = Ember.get(this, 'scrollableArea') || window;
      var elem = (0, _findElem.default)(context);

      if (elem) {
        elem.removeEventListener('scroll', function () {
          _this5._debouncedEventHandler('_triggerDidScrollDirection', elem, Ember.get(_this5, 'viewportScrollSensitivity'));
        });
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },
    _bindListeners: function _bindListeners() {
      var _this6 = this;

      var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      (false && !(context) && Ember.assert('You must pass a valid context to _bindListeners', context));
      (false && !(event) && Ember.assert('You must pass a valid event to _bindListeners', event));


      var elem = (0, _findElem.default)(context);

      elem.addEventListener(event, function () {
        _this6._debouncedEventHandler('_setViewportEntered');
      });
    },
    _unbindListeners: function _unbindListeners() {
      var _this7 = this;

      if (this.intersectionObserver) {
        this.intersectionObserver.unobserve(this.element);
        return;
      }

      if (Ember.get(this, 'viewportUseRAF')) {
        var elementId = Ember.get(this, 'elementId');

        Ember.run.next(this, function () {
          window.cancelAnimationFrame(rAFIDS[elementId]);
          delete rAFIDS[elementId];
        });
        return;
      }

      Ember.get(this, 'viewportListeners').forEach(function (listener) {
        var context = listener.context,
            event = listener.event;

        context = Ember.get(_this7, 'scrollableArea') || context;
        var elem = (0, _findElem.default)(context);

        elem.removeEventListener(event, function () {
          _this7._debouncedEventHandler('_setViewportEntered');
        });
      });

      this._unbindScrollDirectionListener();
    }
  });
});